import React, { useRef, useEffect, useState, lazy, Suspense } from "react";
import SlideItemOne from "./SlideItemOne";
import { BannerOneSection } from "src/data/bannerSection";
import loader from 'src/assets/images/fancybox/fancybox_loading@2x.gif'

const TinySlider = lazy(() => import("src/components/TinySlider/TinySlider"));

const settings = {
  container: ".my-slider-16",
  loop: true,
  lazyload: true,
  nav: false,
  mouseDrag: true,
  items: 1,
  autoplay: true,
  autoHeight: true,
  controls: true,
  controlsContainer: ".tns-controls",
  gutter: 0,
  autoplayButton: false,
  autoplayButtonOutput: false,
};

const BannerOne = () => {
  const listRef = useRef(null);
  const sectionData = BannerOneSection();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="banner-section banner-one banner-one-page" id="home">
      <div className="banner-carousel">
        <Suspense fallback={loader}>
          <TinySlider options={settings} ref={listRef}>
            {sectionData.banners.map(banner => (
              <SlideItemOne
                slide={banner}
                windowSize={windowSize}
                key={banner.id}
              />
            ))}
          </TinySlider>
        </Suspense>
        <div className="tns-controls">
          <button className="tns-prev">
            <span className="icon fa fa-angle-left"></span>
          </button>
          <button className="tns-next">
            <span className="icon fa fa-angle-right"></span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default BannerOne;
