import servicesSectionThree from "src/data/servicesSection";
import useActive from "src/hooks/useActive";
import React from "react";
import { Row } from "react-bootstrap";
import SingleService from "./SingleService";


const ServicesSectionThree = () => {
  const ref = useActive("#services");

  const { services } = servicesSectionThree();

  return (
    <section
      ref={ref}
      className={`services-section-three`}
      id="services"
    >
      <div className="auto-container">
        <div className="sec-title centered">
        </div>
        <div className="services">
          <Row className="clearfix">
            {services.map((service) => (
              <SingleService service={service} key={service.id} />
            ))}
          </Row>
        </div>
      </div>
    </section>
  );
};

export default ServicesSectionThree;
