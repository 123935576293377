import React from "react";
import { Col } from "react-bootstrap";
import TextSplit from "../Reuseable/TextSplit";

const SingleServiceOne = ({ service = {} }) => {
  return (
    <Col xl={6} lg={6} md={6} sm={12} className="service-block-two d-flex">
      <div className="inner-box animated fadeInUp flex-fill">
        <h5>
          <TextSplit text={service.category} />
        </h5>

        <ul className="text">
          {service.services?.map((item, index) => (
            <li key={index} className="bullet-item">
              <span className="bullet-icon">• </span> 
              <span className="item-title">{item.title}</span>
            </li>
          ))}
        </ul>
      </div>
    </Col>
  );
};

export default SingleServiceOne;
