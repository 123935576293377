import React from "react";
import { Col } from "react-bootstrap";
import TextSplit from "../Reuseable/TextSplit";

const SingleService = ({ service = {} }) => {
  const { icon, title, text } = service;

  return (
    <Col xl={3} lg={6} md={6} sm={12} className="service-block-two d-flex">
      <div className="inner-box animated fadeInUp flex-fill">
        <div className="bottom-curve"></div>
        <div className="icon-box" style={{ height: '90px' }}>
          <img 
            src={icon} 
            className="icon-service"
            style={{ width: '70px', height: '70px' }} 
            alt={title} 
          />
        </div>
        <h5>
          <a href="/services">
            <TextSplit text={title} />
          </a>
        </h5>
        <div className="text">{text}</div>
        <div className="link-box">
          <a href="/services">
            <span className="fa fa-angle-right"></span>
          </a>
        </div>
      </div>
    </Col>
  );
};

export default SingleService;
