import { ContactInfoSection } from 'src/data/contactInfoSection';
import useActive from 'src/hooks/useActive';
import React, { lazy, Suspense } from 'react';
import GetQuoteTwo from 'src/components/GetQuote/GetQuoteTwo';
import { Container } from 'react-bootstrap';
import loader from 'src/assets/images/fancybox/fancybox_loading@2x.gif'

const MapBox = lazy(() => import('../MapSection/MapBox'));

const ContactSection = ({ className = '', form = false, map = false, isHomePage = false }) => {
  const ref = useActive('#contact');
  const sectionData = ContactInfoSection();
  return (
    <section
      ref={ref}
      className={`contact-section ${className}`}
      id="contact"
      style={isHomePage ? { padding: '10px 0px 0px' } : {}}
    >
      {isHomePage && (
        <div className="sec-title centered">
          <h2>{sectionData.title}</h2>
        </div>
      )}
      <Container className="mt-4">
        <GetQuoteTwo className="get-quote-two__one-page" formFields={sectionData.inputs} />
      </Container>
      {map && <Suspense fallback={loader}><MapBox /></Suspense>}
    </section>
  );
};

export default ContactSection;
