import { useTranslation } from 'react-i18next';

const useGetQuoteData = () => {
  const { t } = useTranslation();

  const getQuote = {
    featuredTitle: t("getQuote.featuredTitle"),
    text: t("getQuote.text"),
    count: 345600,
    counterTitle: t("getQuote.counterTitle"),
    formTitle: t("getQuote.formTitle"),
    inputs: [
      {
        name: "username",
        type: "text",
        placeholder: t("getQuote.inputs.username"),
      },
      {
        name: "email",
        type: "email",
        placeholder: t("getQuote.inputs.email"),
      },
      {
        name: "phone",
        type: "text",
        placeholder: t("getQuote.inputs.phone"),
      },
    ],
  };

  const getQuoteTwo = {
    title: t("getQuoteTwo.title"),
    text: t("getQuoteTwo.text"),
    addressTitle: t("getQuoteTwo.addressTitle"),
    address1: "Williama Heerleina Lindleya 16/401,\n 02-013 Warszawa",
    address2: " ul. Targowa 1,\n 09-410 Płock",
    emailTitle: t("getQuoteTwo.emailTitle"),
    phoneTitle: t("getQuoteTwo.callTitle"),
    submitButton: t("getQuoteTwo.submitButton"),
    email: "equipp@wmmgroup.pl",
    phone: "+48 575 767 785",
    inputs: [
      {
        type: "text",
        name: "username",
        placeholder: t("getQuoteTwo.inputs.username"),
      },
      {
        type: "email",
        name: "email",
        placeholder: t("getQuoteTwo.inputs.email"),
      },
      {
        type: "text",
        name: "phone",
        placeholder: t("getQuoteTwo.inputs.phone"),
      },
      {
        type: "text",
        name: "subject",
        placeholder: t("getQuoteTwo.inputs.subject"),
      },
      {
        name: "message",
        placeholder: t("getQuoteTwo.inputs.message"),
      },
    ],
  };

  const getQuoteThree = {
    title: t("getQuoteThree.title"),
    inputs: [
      {
        type: "text",
        name: "username",
        placeholder: t("getQuoteThree.inputs.username"),
      },
      {
        type: "email",
        name: "email",
        placeholder: t("getQuoteThree.inputs.email"),
      },
      {
        type: "text",
        name: "phone",
        placeholder: t("getQuoteThree.inputs.phone"),
      },
      {
        type: "text",
        name: "subject",
        placeholder: t("getQuoteThree.inputs.subject"),
      },
      {
        name: "message",
        placeholder: t("getQuoteThree.inputs.message"),
      },
    ],
  };

  return { getQuote, getQuoteTwo, getQuoteThree };
};

export default useGetQuoteData;
