// React and router imports
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Context provider import
import ContextProvider from "./context/ContextProvider";

// Page component import
import HomePage from './pages/home';
import ServicesPage from './pages/services';
import PortfolioPage from './pages/portfolio';
import AboutPage from './pages/about';
import ContactPage from './pages/contact';
import CareerPage from './pages/career';
import TermsOfServicePage from './pages/terms-of-service';
import PrivacyPolicyPage from './pages/privacy-policy';

// Vendor CSS files
import "src/assets/vendors/animate.css"; 
import "src/assets/vendors/custom-animate.css";
import "src/assets/vendors/flaticon.css";
import "src/assets/vendors/fontawesome-all.css";
import "src/assets/vendors/reey-font.css";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Additional CSS libraries
import "react-circular-progressbar/dist/styles.css";
import "tiny-slider/dist/tiny-slider.css";

// Custom styles
import "./styles/style.css";
import "./styles/hover.css";
import "./styles/responsive.css";

import 'normalize.css';

import './i18n';

function App() {
  return (
    <BrowserRouter>
      <ContextProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/career" element={<CareerPage />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
      </ContextProvider>
    </BrowserRouter>
  );
}

export default App;
