import sponsors from "src/data/sponsorsSection";
import React, { useRef, lazy, Suspense } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import loader from 'src/assets/images/fancybox/fancybox_loading@2x.gif'

const TinySlider = lazy(() => import("src/components/TinySlider/TinySlider"));

const settings = {
  loop: true,
  lazyload: true,
  nav: false,
  mouseDrag: true,
  items: 11,
  autoplay: true,
  autoHeight: true,
  controls: false,
  gutter: 0,
  autoplayButton: false,
  autoplayButtonOutput: false,
  responsive: {
    300: {
      items: 3,
      gutter: 30,
    },
    600: {
      items: 4,
      gutter: 30,
    },
    992: {
      items: 5,
      gutter: 30,
    },
    1200: {
      items: 7,
      gutter: 30,
    },
  },
};

const SponsorsSection = ({ className = "" }) => {
  const { t } = useTranslation();
  const listRef = useRef(null);

  return (
    <section className={`sponsors-section ${className}`}>
      <div className="sponsors-outer">
        <div className="auto-container">
          <div className="sponsors sec-title">
            <h2>
              {t("sponsorsSection.title")}
            </h2>
          </div>
          <div className="sponsors-carousel">
            <Suspense fallback={loader}>
              <TinySlider
                options={{ ...settings, container: `.my-slider-15` }}
                ref={listRef}
              >
                {sponsors.map((image, index) => (
                  <div
                    ref={listRef}
                    key={index}
                    className="slide-item text-center"
                  >
                    <figure className="image-box">
                      <Image
                        src={require(`src/assets/images/clients/${className.includes("dark") ? "dark-1.png" : image
                          }`)}
                        alt=""
                      />
                    </figure>
                  </div>
                ))}
              </TinySlider>
            </Suspense>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SponsorsSection;
