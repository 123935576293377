import { openDB } from 'idb';

const DB_NAME = 'videoCache';
const STORE_NAME = 'videos';

export const getVideoFromCache = async (key) => {
    try {
        const db = await openDB(DB_NAME, 1, {
            upgrade(db) {
                if (!db.objectStoreNames.contains(STORE_NAME)) {
                    db.createObjectStore(STORE_NAME);
                }
            },
        });
        const cachedBlob = await db.get(STORE_NAME, key);
        return cachedBlob || null;
    } catch (error) {
        console.error('Failed to get video from cache:', error);
        return null;
    }
};

export const saveVideoToCache = async (key, blob) => {
    try {
        const db = await openDB(DB_NAME, 1, {
            upgrade(db) {
                if (!db.objectStoreNames.contains(STORE_NAME)) {
                    db.createObjectStore(STORE_NAME);
                }
            },
        });
        await db.put(STORE_NAME, blob, key);
        return true;
    } catch (error) {
        console.error('Failed to save video to cache:', error);
        return false;
    }
};
