import React, { useState, useEffect, useRef } from 'react';
import i18n from 'src/i18n';

const LanguagePicker = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const pickerRef = useRef(null);

  const languages = [
    { code: 'en', flag: 'ENG' },
    { code: 'pl', flag: 'PLN' }
  ];

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="language-picker" ref={pickerRef}>
      <button
        className="language-picker__button"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        aria-label="Select language"
        style={{ display: 'flex', alignItems: 'center', padding: '0 8px' }}
      >
        <span className="fa fa-globe" style={{ marginRight: '5px', }}></span>
        <span
          className="language-picker__flag"
          style={{ paddingTop: '2px', }}
        >
          {languages.find(lang => lang.code === selectedLanguage)?.flag}
        </span>
      </button>
      {isOpen && (
        <div className="language-picker__dropdown">
          {languages.map((language) => (
            <div
              key={language.code}
              className={`language-picker__item ${language.code === selectedLanguage ? 'selected' : ''}`}
              onClick={() => handleLanguageChange(language.code)}
            >
              <span className="language-picker__flag">{language.flag}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguagePicker;
