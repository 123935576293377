import { useTranslation } from 'react-i18next';
import image1 from 'src/assets/images/resource/about1.jpg';
import image2 from 'src/assets/images/resource/about2.jpg';

export const AboutSectionOne = () => {
  const { t } = useTranslation();
  return {
    image1,
    image2,
    title: t('aboutSectionOne.title'),
    text2: t('aboutSectionOne.text2'),
    discoverMore: t('aboutSectionOne.discoverMore'),
    textList: [
      t('aboutSectionOne.textList.0'),
      t('aboutSectionOne.textList.1'),
      t('aboutSectionOne.textList.2'),
      t('aboutSectionOne.textList.3')
    ]
  };
};
