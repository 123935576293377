import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { CareerInfoSection } from 'src/data/careerInfoSection';
import useActive from 'src/hooks/useActive';

const CareerSection = () => {
  const ref = useActive('#career');
  const sectionData = CareerInfoSection();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log('Form submitted:', data);
    alert('Form submitted successfully!');
  };

  return (
    <section ref={ref} className="career-section" id="career">
      <Container className="mt-4">
        <div className="auto-container">
          <Row className="clearfix">
            <Col lg={6} md={12} sm={12} className="left-col">
              <div className="inner">
                <div className="sec-title">
                  <h2>{sectionData.title}</h2>
                  <div className="text">
                    {sectionData.text}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12} className="right-col">
              <div className="inner-box">
                <div className="form-box">
                  <div className="default-form">
                    <form onSubmit={handleSubmit(onSubmit)} id="contact-form">
                      <Row className="clearfix">
                        {sectionData.inputs.map(({ name, type, placeholder, validation }) => (
                          <Col
                            key={name}
                            lg={type !== 'textarea' ? 6 : 12}
                            md={type !== 'textarea' ? 6 : 12}
                            sm={12}
                            className="form-group"
                          >
                            <div className="field-inner">
                              {type === 'textarea' ? (
                                <textarea
                                  id={name}
                                  name={name}
                                  placeholder={placeholder}
                                  {...register(name, { required: true })}
                                  aria-required="true"
                                ></textarea>
                              ) : (
                                <input
                                  id={name}
                                  type={type}
                                  name={name}
                                  placeholder={placeholder}
                                  {...register(name, {
                                    required: true,
                                    ...(validation || {}),
                                  })}
                                  aria-required="true"
                                  aria-describedby={errors[name] ? `${name}-error` : undefined}
                                />
                              )}
                              {errors[name] && (
                                <label
                                  id={`${name}-error`}
                                  htmlFor={name}
                                  className="error"
                                >
                                  {errors[name]?.message || sectionData.requiredField}
                                </label>
                              )}
                            </div>
                          </Col>
                        ))}
                        <Col lg={12} md={12} sm={12} className="form-group">
                          <div className="field-inner">
                            <label htmlFor="attachments">
                              {sectionData.attachments}
                            </label>
                            <input
                              type="file"
                              name="attachments"
                              id="attachments"
                              {...register('attachments', { required: true })}
                              className="form-control"
                              aria-required="true"
                            />
                            {errors.attachments && (
                              <label
                                id="attachments-error"
                                htmlFor="attachments"
                                className="error"
                              >
                              </label>
                            )}
                          </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} className="form-group">
                          <button
                            className="theme-btn btn-style-one"
                            type="submit"
                          >
                            <i className="btn-curve"></i>
                            <span className="btn-title">{sectionData.submitButton}</span>
                          </button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default CareerSection;
