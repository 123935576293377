import React, { useRef, useEffect, useState } from 'react';
import { getVideoFromCache, saveVideoToCache } from 'src/utils/indexedDB';

export const VIDEO_KEY_GUID = '123e4567-e89b-12d3-a456-426614174000';

const videoStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  pointerEvents: 'none'
};

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);
  const [videoURL, setVideoURL] = useState('');

  useEffect(() => {
    const loadVideo = async () => {
      try {
        let cachedVideo = await getVideoFromCache(VIDEO_KEY_GUID);
        if (cachedVideo) {
          console.log('Loaded video from cache.');
          const objectURL = URL.createObjectURL(cachedVideo);
          setVideoURL(objectURL);
        } else {
          console.log('Fetching video from source.');
          const response = await fetch(src);

          if (!response.ok) {
            throw new Error(`Failed to fetch video from source: ${response.statusText}`);
          }

          const blob = await response.blob();
          const saveSuccess = await saveVideoToCache(VIDEO_KEY_GUID, blob);

          if (!saveSuccess) {
            console.warn('Failed to save video to cache.');
          }

          const objectURL = URL.createObjectURL(blob);
          setVideoURL(objectURL);
        }
      } catch (error) {
        console.error('Error loading video:', error);
        setVideoURL(src);
      }
    };

    loadVideo();
  }, [src]);

  useEffect(() => {
    const setPlaybackRate = () => {
      if (videoRef.current) {
        videoRef.current.playbackRate = 0.4;
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', setPlaybackRate);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', setPlaybackRate);
      }
    };
  }, [videoURL]);

  return (
    <video
      ref={videoRef}
      style={videoStyle}
      src={videoURL}
      autoPlay
      loop
      muted
      playsInline
    />
  );
};

export default VideoPlayer;
