import React from "react";
import { Col, Row } from "react-bootstrap";
import FeaturedSection from "src/data/featuredSection";

const WMMFeaturedSection = ({ className = "" }) => {
  const { title, text, sectionTitle, features, partnershipText } = FeaturedSection();

  return (
    <section className={`featured-section ${className}`} style={{ width: '100%' }}>
      <div className="auto-container">
        <Row>
          <Col md={12} sm={12} className="right-col">
            <div className="inner">
              <div className="sec-title mb-4">
                <h2>{title}</h2>
                <div className="lower-text">{text}</div>
              </div>
              <h3>{sectionTitle}</h3>
              <div className="services mb-4">
                <Row>
                  {features.map(({ id, title, text }) => (
                    <Col key={id} md={6} sm={12} className="service mb-3">
                      <div className="inner-box p-3">
                        <h6>{title}</h6>
                        <div className="text">{text}</div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="partnership mt-4">
                <p>{partnershipText}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default WMMFeaturedSection;
