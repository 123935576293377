import useProfileData from "src/data/portfolioSection";
import React from "react";
import useActive from "src/hooks/useActive";
import { Row } from "react-bootstrap";
import PortfolioServiceOne from './PortfolioServiceOne';

const PortfolioNine = () => {
  const ref = useActive("#services");
  const { portfolioNine, portfolioNineButton } = useProfileData();

  return (
    <section
      ref={ref}
      className={`services-section-three`}
      id="services"
    >
      <div className="auto-container">
        <div className="services">
          <Row className="clearfix">
            {portfolioNine.map((portfolio) => (
              <PortfolioServiceOne portfolio={portfolio} portfolioButton={portfolioNineButton} />
            ))}
          </Row>
        </div>
      </div>
    </section>
  );
};

export default PortfolioNine;
