import { useTranslation } from 'react-i18next';

const FeaturedSection = () => {
  const { t } = useTranslation();
  return {
    title: t('featuredSection.title'),
    text: t('featuredSection.text'),
    sectionTitle: t('featuredSection.sectionTitle'),
    features: [
      {
        id: 1,
        title: t('featuredSection.features.0.title'),
        text: t('featuredSection.features.0.text')
      },
      {
        id: 2,
        title: t('featuredSection.features.1.title'),
        text: t('featuredSection.features.1.text')
      },
      {
        id: 3,
        title: t('featuredSection.features.2.title'),
        text: t('featuredSection.features.2.text')
      },
      {
        id: 4,
        title: t('featuredSection.features.3.title'),
        text: t('featuredSection.features.3.text')
      }
    ],
    partnershipText: t('featuredSection.partnershipText')
  };
};

export default FeaturedSection;
