import React, { lazy, Suspense } from 'react';
import useParallaxData  from "src/data/parallaxSection"; 
import JarallaxImage from "../Jarallax/JarallaxImage";
import tools from 'src/assets/images/tools.png';

import loader from 'src/assets/images/fancybox/fancybox_loading@2x.gif'

const Jarallax = lazy(() => import("../Jarallax/Jarallax"));

const ParallaxSection = () => {
  const { parallaxSection } = useParallaxData(); 
  const { bg, title, title2 } = parallaxSection;

  return (
    <Suspense fallback={loader}>
    <Jarallax speed={0.3} imgPosition="50% 80%" className="parallax-section">
      <JarallaxImage src={bg} />
      <div className="auto-container">
        <div className="content-box">
          <div className="icon-box">
            <span>
              <img src={tools} style={{ width: '60px', height: '60px' }} />
            </span>
          </div>
          <h2>
            {title} <span>{title2}</span>
          </h2>
        </div>
      </div>
    </Jarallax>
    </Suspense>
  );
};

export default ParallaxSection;
