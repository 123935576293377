import { useRootContext } from "src/context/context";
import { Link, useLocation } from "react-router-dom";
import React from "react";

const NavItem = ({ navItem = {}, onePage = false }) => {
  const { pathname } = useLocation();
  const { currentActive } = useRootContext();

  const { name, href, subNavItems = [] } = navItem;
  const subHref = subNavItems.map((item) => item.href);
  const current = !onePage
    ? pathname === href || subHref.includes(pathname)
    : currentActive === href;


  return (
    <li className={`dropdown${current ? " current" : ""}`}>
      <Link to={href}>
        {name}
      </Link>
    </li>
  );
};

export default NavItem;
