import { useTranslation } from "react-i18next";
import bg from "src/assets/images/resource/paralax.jpg";

const useParallaxData = () => {
  const { t } = useTranslation();

  const parallaxSection = {
    bg,
    icon: "flaticon-app-development",
    title: t("parallaxSection.title"),
    title2: t("parallaxSection.title2"),
  };

  const parallaxPage = [
    {
      id: 1,
      bg: "parallax-bg-1-1.jpg",
      title: t("parallaxSection.parallaxPage.1"),
    },
    {
      id: 2,
      bg: "parallax-bg-1-2.jpg",
      title: t("parallaxSection.parallaxPage.2"),
    },
    {
      id: 3,
      bg: "parallax-bg-1-3.jpg",
      title: t("parallaxSection.parallaxPage.3"),
    },
    {
      id: 4,
      bg: "parallax-bg-1-4.jpg",
      title: t("parallaxSection.parallaxPage.4"),
    },
  ];

  return { parallaxSection, parallaxPage };
};

export default useParallaxData;
