import { useTranslation } from 'react-i18next';
import construction from 'src/assets/images/services/construction-site.png';
import specializedEquipment from 'src/assets/images/services/helmet.png';
import salesOfMaterials from 'src/assets/images/services/raw-materials.png';
import container from 'src/assets/images/services/container.png';

const useServicesData = () => {
  const { t } = useTranslation();

  const services = [
    {
      id: 1,
      icon: construction,
      title: t("services.constructionTitle"),
      text: t("services.constructionText"),
    },
    {
      id: 2,
      icon: specializedEquipment,
      title: t("services.equipmentRentalTitle"),
      text: t("services.equipmentRentalText"),
    },
    {
      id: 3,
      icon: salesOfMaterials,
      title: t("services.materialSalesTitle"),
      text: t("services.materialSalesText"),
    },
    {
      id: 4,
      icon: container,
      title: t("services.containerSalesTitle"),
      text: t("services.containerSalesText"),
    },
  ];

  const servicesSection = {
    title: t("services.sectionTitle"),
    text: t("services.sectionText"),
    services,
  };

  const serviceNine = [
    {
      id: 1,
      category: t("services.serviceNine.earthworksCategory"),
      services: t("services.serviceNine.earthworksServices", { returnObjects: true }).map(title => ({ title })),
    },
    {
      id: 2,
      category: t("services.serviceNine.sanitaryCategory"),
      services: t("services.serviceNine.sanitaryServices", { returnObjects: true }).map(title => ({ title })),
    },
    {
      id: 3,
      category: t("services.serviceNine.electricalCategory"),
      services: t("services.serviceNine.electricalServices", { returnObjects: true }).map(title => ({ title })),
    },
    {
      id: 4,
      category: t("services.serviceNine.drainageCategory"),
      services: t("services.serviceNine.drainageServices", { returnObjects: true }).map(title => ({ title })),
    },
    {
      id: 5,
      category: t("services.serviceNine.finishingCategory"),
      services: t("services.serviceNine.finishingServices", { returnObjects: true }).map(title => ({ title })),
    },
    {
      id: 6,
      category: t("services.serviceNine.machineryRentalCategory"),
      services: t("services.serviceNine.machineryRentalServices", { returnObjects: true }).map(title => ({ title })),
    },
  ];

  return { services, servicesSection, serviceNine };
};

export default useServicesData;
