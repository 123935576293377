import { useTranslation } from 'react-i18next';

export const ContactInfoSection = () => {
  const { t } = useTranslation();
  return {
    title: t('contactSection.title'),
    inputs: [
      {
        name: 'username',
        type: 'text',
        placeholder: t('contactSection.inputs.username'),
        required: true,
      },
      {
        name: 'email',
        type: 'email',
        placeholder: t('contactSection.inputs.email'),
        required: true,
        validation: {
          pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
          message: t('contactSection.inputs.emailInvalid'),
        },
      },
      {
        name: 'phone',
        type: 'text',
        placeholder: t('contactSection.inputs.phone'),
        required: true,
        validation: {
          pattern: /^[0-9]{10,15}$/,
          message: t('contactSection.inputs.phoneInvalid'),
        },
      },
      {
        name: 'subject',
        type: 'text',
        placeholder: t('contactSection.inputs.subject'),
        required: true,
      },
      {
        name: 'message',
        placeholder: t('contactSection.inputs.message'),
        required: true,
      },
    ],
    contacts: [
      {
        id: 1,
        address: t('contactSection.contacts.address'),
        email: t('contactSection.contacts.email'),
        nip: t('contactSection.contacts.nip'),
      },
    ],
  };
};
