import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import context from "./context";

const ContextProvider = ({ children }) => {
  const [menuStatus, setMenuStatus] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [currentActive, setCurrentActive] = useState("#home");

  const { pathname } = useLocation();

  const toggleMenu = (value) => {
    setMenuStatus((prevMenuStatus) =>
      typeof value === "boolean" ? value : !prevMenuStatus
    );
  };

  const toggleSearch = () => {
    setOpenSearch((prevSearch) => !prevSearch);
  };

  useEffect(() => {
    toggleMenu(false);
  }, [pathname]);

  const value = {
    menuStatus,
    openSearch,
    toggleMenu,
    toggleSearch,
    currentActive,
    setCurrentActive,
  };

  return <context.Provider value={value}>{children}</context.Provider>;
};

export default ContextProvider;
