import React from "react";
import PageBanner from "src/components/BannerSection/PageBanner";
import ContactSection from "src/components/ContactSection/ContactSection";
import HeaderOne from "src/components/Header/HeaderOne";
import MobileMenu from "src/components/Header/MobileMenu";
import Layout from "src/components/Layout/Layout";
import MainFooter from "src/components/MainFooter/MainFooter";
import Style from "src/components/Reuseable/Style";
import SearchPopup from "src/components/SearchPopup/SearchPopup";
import { useTranslation } from 'react-i18next';

const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <Layout pageTitle={t('pageBanner.contact')}>
      <Style />
      <HeaderOne
        topBar
        logo={3}
        headerStyle="header-style-seven"
        autoContainer
        links={false}
      />
      <MobileMenu />
      <SearchPopup />
      <PageBanner title={t('pageBanner.contact')} home={t('pageBanner.home')} />
      <ContactSection map form />
      <MainFooter />
    </Layout>
  );
};

export default ContactPage;
