import React, { useState } from "react";
import { Col, Button } from "react-bootstrap";
import TextSplit from "../Reuseable/TextSplit";

const PortfolioServiceOne = ({ portfolio = {}, portfolioButton = {} }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Col xl={6} lg={6} md={6} sm={12} className="service-block-two d-flex">
      <div className="inner-box animated fadeInUp flex-fill">
        <h5>
          <TextSplit text={portfolio.category} />
        </h5>
        <div className="text">
          {isExpanded ? (
            <>
              {portfolio.description}
            </>
          ) : (
            <>
              {portfolio.descriptionPreview}
            </>
          )}
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="link"
              onClick={toggleExpand}
              style={{ padding: 0, cursor: "pointer" }}
            >
              {isExpanded ? String(portfolioButton.buttonAfter) : String(portfolioButton.buttonBefore)}
            </Button>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default PortfolioServiceOne;
