import { useRootContext } from "src/context/context";
import useHeaderData from "src/data/header";
import useScroll from "src/hooks/useScroll";
import { Link } from 'react-router-dom';
import React from "react";
import { Image } from "react-bootstrap";
import NavItem from "./NavItem";
import LanguagePicker from "./LanguagePicker";

const HeaderOne = ({
  headerStyle = "header-style-one",
  onePage = false,
  topBar = false,
  autoContainer = false,
  links = true,
  rightMenu = false,
}) => {
  const { scrollTop } = useScroll(120);
  const { toggleMenu, toggleSearch } = useRootContext();

  const {
    title,
    logo,
    navItems,
    navItemsTwo,
    phone,
    socials
  } = useHeaderData();

  const newNavItems = onePage ? navItemsTwo : navItems;

  return (
    <header
      className={`main-header${scrollTop ? " fixed-header" : ""
        } ${headerStyle}`}
    >
      {topBar && (
        <div className="topbar-four">
          <div className="auto-container">
            <p>{title}</p>
            <div className="right-content d-flex align-items-center">
              <div className="phone me-3">
                <span className="icon flaticon-call"></span>
                <a href={`tel:${phone.split(" ").join("")}`}>{phone}</a>
              </div>

              <ul className="list-unstyled social-links me-3">
                {socials.map(({ id, icon, href }) => (
                  <li key={id}>
                    <a href={href} target="_blank" rel="noopener noreferrer">
                      <span className={icon}></span>
                    </a>
                  </li>
                ))}
              </ul>

              <div className="language-picker-wrapper">
                <LanguagePicker className="language-picker-icon" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="header-upper">
        <div className={autoContainer ? "inner-container clearfix" : ""}>
          <div
            className={
              autoContainer ? "auto-container" : "inner-container clearfix"
            }
          >
            <div className="logo-box">
              <div className="logo">
                <Link to="/">
                  <a title={title}>
                    <Image
                      id="thm-logo"
                      alt="WWM Group"
                      src={logo}
                      title={title}
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="nav-outer clearfix">
              <div onClick={toggleMenu} className="mobile-nav-toggler">
                <span className="icon flaticon-menu-2"></span>
                <span className="txt">Menu</span>
              </div>

              <nav className="main-menu navbar-expand-md navbar-light">
                <div
                  className={
                    autoContainer
                      ? ""
                      : "collapse navbar-collapse show clearfix"
                  }
                  id={autoContainer ? "" : "navbarSupportedContent"}
                >
                  <ul className="navigation clearfix">
                    {newNavItems.map((navItem) => (
                      <NavItem
                        navItem={navItem}
                        key={navItem.id}
                        onePage={onePage}
                      />
                    ))}
                  </ul>
                </div>
              </nav>
            </div>

            {links && (
              <div className="other-links clearfix">
                <div className="link-box">
                  <div className="call-us">
                    <a
                      className="link"
                      href={`tel:${phone.split(" ").join("")}`}
                    >
                      <span className="icon"></span>
                      <span className="sub-text">Call Anytime</span>
                      <span className="number">{phone}</span>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {rightMenu && (
              <div className="right-menu">
                <div className="search-btn">
                  <button
                    onClick={toggleSearch}
                    type="button"
                    className="theme-btn search-toggler"
                  >
                    <span className="flaticon-loupe"></span>
                  </button>
                </div>
                <div onClick={toggleMenu} className="mobile-nav-toggler">
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderOne;