import React from "react";
import PageBanner from "src/components/BannerSection/PageBanner";
import FeaturedSection from "src/components/FeaturedSection/FeaturedSection";
import HeaderOne from "src/components/Header/HeaderOne";
import MobileMenu from "src/components/Header/MobileMenu";
import Layout from "src/components/Layout/Layout";
import MainFooter from "src/components/MainFooter/MainFooter";
import Style from "src/components/Reuseable/Style";
import { useTranslation } from 'react-i18next';

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <Layout pageTitle={t('pageBanner.about')}>
      <Style />
      <HeaderOne
        topBar
        logo={3}
        headerStyle="header-style-seven"
        autoContainer
        links={false}
      />
      <MobileMenu />
      <PageBanner title={t('pageBanner.about')} page={t('pageBanner.about')} home={t('pageBanner.home')} />
      <FeaturedSection className="featured-section__about-two" />
      <MainFooter />
    </Layout>
  );
};

export default AboutPage;
