import React from "react";
import PageBanner from "src/components/BannerSection/PageBanner";
import HeaderOne from "src/components/Header/HeaderOne";
import MobileMenu from "src/components/Header/MobileMenu";
import Layout from "src/components/Layout/Layout";
import MainFooter from "src/components/MainFooter/MainFooter";
import Style from "src/components/Reuseable/Style";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';

const PrivacyPolicyPage = () => {
    const { t } = useTranslation();
    return (
        <Layout pageTitle={t('pageBanner.privacyPolicy')}>
            <Style />
            <HeaderOne
                topBar
                logo={3}
                headerStyle="header-style-seven"
                autoContainer
                links={false}
            />
            <MobileMenu />
            <PageBanner title={t('pageBanner.privacyPolicy')} home={t('pageBanner.home')} />
            <Container className="my-5">
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Card>
                            <Card.Body>
                                <Card.Subtitle className="mb-3">Administrator Danych Osobowych</Card.Subtitle>
                                <Card.Text>
                                    Administratorem Danych Osobowych jest WWM Group Spółka z ograniczoną odpowiedzialnością, z siedzibą przy Zbożowa 7 / 9, 09-410 Płock, NIP: 5272996938, REGON: 521653380, KRS: 0000963914.
                                </Card.Text>

                                <Card.Subtitle className="mb-3">Zakres i Cel Przetwarzania Danych</Card.Subtitle>
                                <Card.Text>
                                    <ul>
                                        <li>Formularz Kontaktowy: Dane podane za pomocą formularza kontaktowego są przetwarzane w celu odpowiedzi na zapytania użytkowników.</li>
                                        <li>Rekrutacja: Dane przesyłane w ramach aplikacji na stanowiska pracy są przetwarzane w celu realizacji procesu rekrutacji.</li>
                                        <li>Newsletter: Adresy e-mail zbierane poprzez formularz subskrypcji są wykorzystywane do przesyłania informacji o aktualnościach i ofertach WWM Group.</li>
                                    </ul></Card.Text>

                                <Card.Subtitle className="mb-3">Podstawa Prawna Przetwarzania</Card.Subtitle>
                                <Card.Text>    <ul>
                                    <li>Art. 6 ust. 1 lit. a) RODO – zgody osoby, której dane dotyczą.</li>
                                    <li>Art. 6 ust. 1 lit. b) RODO – niezbędności do wykonania umowy lub podjęcia działań przed jej zawarciem.</li>
                                    <li>Art. 6 ust. 1 lit. c) RODO – wypełnienia obowiązku prawnego.</li>
                                    <li>Art. 6 ust. 1 lit. f) RODO – uzasadnionego interesu administratora danych, np. marketingu bezpośredniego.</li>
                                </ul></Card.Text>


                                <Card.Subtitle className="mb-3">Prawa Osób, Których Dane Dotyczą</Card.Subtitle>
                                <Card.Text>
                                    <ul>
                                        <li>Dostępu do swoich danych.</li>
                                        <li>Sprostowania nieprawidłowych danych.</li>
                                        <li>Żądania usunięcia danych.</li>
                                        <li>Ograniczenia przetwarzania danych.</li>
                                        <li>Przenoszenia danych.</li>
                                        <li>Wniesienia sprzeciwu wobec przetwarzania.</li>
                                        <li>Cofnięcia zgody w dowolnym momencie, bez wpływu na zgodność przetwarzania sprzed jej cofnięcia.</li>
                                    </ul>
                                </Card.Text>

                                <Card.Subtitle className="mb-3">Odbiorcy Danych</Card.Subtitle>
                                <Card.Text>
                                    Dane mogą być przekazywane podmiotom przetwarzającym dane na zlecenie WWM Group, np. dostawcom usług IT, lub organom uprawnionym na mocy prawa.
                                </Card.Text>

                                <Card.Subtitle className="mb-3">Przekazywanie Danych do Państw Trzecich</Card.Subtitle>
                                <Card.Text>
                                    Dane osobowe mogą być przekazywane poza EOG na podstawie odpowiednich zabezpieczeń, takich jak standardowe klauzule ochrony danych zatwierdzone przez Komisję Europejską.
                                </Card.Text>

                                <Card.Subtitle className="mb-3">Okres Przechowywania Danych</Card.Subtitle>
                                <Card.Text>
                                    <ul>
                                        <li>Formularz Kontaktowy: Dane będą przechowywane przez czas niezbędny do udzielenia odpowiedzi na zapytania oraz przez okres umożliwiający dochodzenie roszczeń.</li>
                                        <li>Rekrutacja: Dane będą przechowywane przez czas trwania procesu rekrutacyjnego, a w przypadku wyrażenia zgody – przez czas wskazany w tej zgodzie.</li>
                                        <li>Newsletter: Dane będą przetwarzane do momentu cofnięcia zgody.</li>
                                    </ul></Card.Text>

                                <Card.Subtitle className="mb-3">Pliki Cookies</Card.Subtitle>
                                <Card.Text>
                                    Strona wykorzystuje pliki cookies w celu zapewnienia podstawowych funkcjonalności oraz analizy ruchu. Użytkownicy mogą zarządzać ustawieniami cookies w swojej przeglądarce.
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <MainFooter />
        </Layout>
    );
};

export default PrivacyPolicyPage;
