import useServiceSection  from "src/data/servicesSection";
import React from "react";
import useActive from "src/hooks/useActive";
import { Row } from "react-bootstrap";
import SingleServiceOne from './SingleServiceOne';

const ServiceNine = () => {
  const ref = useActive("#services");
  const { serviceNine } = useServiceSection();

  return (
    <section
    ref={ref}
    className={`services-section-three`}
    id="services"
  >
    <div className="auto-container">
      <div className="services">
        <Row className="clearfix">
          {serviceNine.map((service) => (
            <SingleServiceOne service={service} key={service.id} />
          ))}
        </Row>
      </div>
    </div>
  </section>
  );
};

export default ServiceNine;
