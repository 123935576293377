import { Link } from "react-router-dom";
import React from "react";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const PageBanner = ({
  title = "",
  page = "",
  parent = "",
  parentHref = "/",
  home = "Home",
}) => {

  const videoSrc = "/videos/14922705-uhd_3840_2160_60fps.mp4";

  return (
    <section className="page-banner">
      <div className="image-layer">
        <VideoPlayer src={videoSrc} />
      </div>
      <div className="shape-1"></div>
      <div className="shape-2"></div>
      <div className="banner-inner">
        <div className="auto-container">
          <div className="inner-container clearfix">
            <h1>{title}</h1>
            <div className="page-nav">
              <ul className="bread-crumb clearfix">
                <li>
                  <Link to="/">{home}</Link>
                </li>
                {parent && (
                  <li>
                    <Link to={parentHref}>{parent}</Link>
                  </li>
                )}
                <li className="active">{page || title}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageBanner;
