import { useTranslation } from "react-i18next";
import icon from "src/assets/images/icons/close-1-1.png";
import logo from "src/assets/images/logo.png";

const useHeaderData = () => {
  const { t } = useTranslation();
  
  const home = {
    id: 1,
    name: t("navigation.home"),
    href: "/"
  };

  const navItemsTwo = [{ ...home, href: "#home" }];

  const navItems = [
    home,
    { id: 2, name: t("navigation.services"), href: "/services" },
    { id: 3, name: t("navigation.projects"), href: "/portfolio" },
    { id: 4, name: t("navigation.career"), href: "/career" },
    { id: 5, name: t("navigation.contact"), href: "/contact" }  
  ];

  const socials = [
    { id: 1, icon: "fab fa-linkedin-in", href: "https://www.linkedin.com/company/105390980/admin/dashboard/" },
    { id: 2, icon: "fab fa-facebook-square", href: "#" }
  ];

  return {
    title: t("header.title"),
    logo,
    navItems,
    navItemsTwo,
    phone: "+48 575 767 785",
    email: "equipp@wmmgroup.pl",
    icon,
    socials,
    text: t("header.welcomeMessage")
  };
};

export default useHeaderData;
