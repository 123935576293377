import { useTranslation } from 'react-i18next';

export const CareerInfoSection = () => {
  const { t } = useTranslation();
  return {
    title: t('careerSection.title'),
    text: t('careerSection.text'),
    inputs: [
      {
        name: 'username',
        type: 'text',
        placeholder: t('careerSection.inputs.username'),
        required: true,
      },
      {
        name: 'email',
        type: 'email',
        placeholder: t('careerSection.inputs.email'),
        required: true,
        validation: {
          pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
          message: t('careerSection.inputs.emailInvalid'),
        },
      },
      {
        name: 'phone',
        type: 'text',
        placeholder: t('careerSection.inputs.phone'),
        required: true,
        validation: {
          pattern: /^[0-9]{10,15}$/,
          message: t('careerSection.inputs.phoneInvalid'),
        },
      },
      {
        name: 'position',
        type: 'text',
        placeholder: t('careerSection.inputs.position'),
        required: true,
      }
    ],
    attachments : t('careerSection.attachments'),
    submitButton: t('careerSection.submitButton'),
  };
};