import { AboutSectionOne } from 'src/data/aboutSection';
import useActive from "src/hooks/useActive";
import { Link } from "react-router-dom";
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import TextSplit from "../Reuseable/TextSplit";

const AboutSection = () => {
  const ref = useActive("#about");
  const sectionData = AboutSectionOne();
  return (
    <section ref={ref} className="about-section" id="about">
      <div className="auto-container">
        <Row className="clearfix">
          <Col xl={6} lg={12} md={12} sm={12} className="image-column">
            <div className="inner">
              <div className="image-block animated fadeInLeft">
                <Image src={sectionData.image1} alt="" style={{ height: '500px' }} />
              </div>
              <div className="image-block animated fadeInUp">
                <Image src={sectionData.image2} alt="" />
              </div>
            </div>
          </Col>
          <Col xl={6} lg={12} md={12} sm={12} className="text-column">
            <div className="inner">
              <div className="sec-title">
                <h2>
                  <TextSplit text={sectionData.title} />
                </h2>
              </div>
              <div className="text">
                <p>{sectionData.text2}</p>
              </div>
              <div className="text clearfix">
                <ul>
                  {sectionData.textList.map((text, i) => (
                    <li key={i}>{text}</li>
                  ))}
                </ul>
              </div>
              <div className="link-box">
                <Link to="/about" className="theme-btn btn-style-one">
                  <i className="btn-curve"></i>
                  <span className="btn-title">{sectionData.discoverMore}</span>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AboutSection;
