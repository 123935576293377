import { useTranslation } from "react-i18next";
import demoBg from "src/assets/images/background/footer-bg-2.jpg";
import logo from "src/assets/images/logo.png";

const useFooterData = () => {
  const { t } = useTranslation();
  return {
    logo,
    bg: demoBg,
    title: t("footer.title"),
    text: t("footer.text"),
    address1: t("footer.address1"),
    address2: t("footer.address2"),
    phone: t("footer.phone"),
    email: t("footer.email"),
    textBottom: t("footer.textBottom"),
    subscribeText: t("footer.subscribeText"),
    socials: [
      {
        id: 1,
        href: "#",
        icon: "fab fa-linkedin-in",
      },
      {
        id: 2,
        href: "#",
        icon: "fab fa-facebook-square",
      },
    ],
    links: [
      { id: 2, href: "/about", title: t("footer.links.about") },
      { id: 1, href: "/", title: t("footer.links.home") },
      { id: 3, href: "/services", title: t("footer.links.services") },
      { id: 4, href: "/portfolio", title: t("footer.links.portfolio") },
      { id: 5, href: "/career", title: t("footer.links.career") },
      { id: 6, href: "/contact", title: t("footer.links.contact") },
      { id: 7, href: "/terms-of-service", title: t("footer.links.termsOfService") },
      { id: 8, href: "/privacy-policy", title: t("footer.links.privacyPolicy") },
    ],
    sections: {
      explore: t("footer.sections.explore"),
      contact: t("footer.sections.contact"),
      newsletter: t("footer.sections.newsletter"),
    },
    year: new Date().getFullYear(),
    author: t("footer.author"),
    newses: [
      {
        id: 1,
        image: "footer-1-1.png",
        date: t("footer.news.date1"),
        title: t("footer.news.title1"),
      },
      {
        id: 2,
        image: "footer-1-2.png",
        date: t("footer.news.date2"),
        title: t("footer.news.title2"),
      },
    ],
  };
};

export default useFooterData;
