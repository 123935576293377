import { useTranslation } from 'react-i18next';

const usePortfolioData = () => {
  const { t } = useTranslation();

  const portfolioNineButton = {
    buttonBefore: t("portfolio.buttonBefore"),
    buttonAfter: t("portfolio.buttonAfter"),
  };

  const portfolioNine = t("portfolio.projects", { returnObjects: true }).map((project) => ({
    id: project.id,
    category: project.title,
    descriptionPreview: project.descriptionPreview,
    description: project.description,
  }));

  return { portfolioNine, portfolioNineButton };
};

export default usePortfolioData;
