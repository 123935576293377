import React from "react";

import AboutSection from "src/components/AboutSection/AboutSection";
import BannerOne from "src/components/BannerSection/BannerOne";
import ContactSection from "src/components/ContactSection/ContactSection";
import HeaderOne from "src/components/Header/HeaderOne";
import MobileMenu from "src/components/Header/MobileMenu";
import Layout from "src/components/Layout/Layout";
import MainFooter from "src/components/MainFooter/MainFooter";
import ParallaxSection from "src/components/ParallaxSection/ParallaxSection";
import Style from "src/components/Reuseable/Style";
import SponsorsSection from "src/components/SponsorsSection/SponsorsSection";
import ServicesSectionThree from "src/components/ServicesSection/ServicesSectionThree";

const HomePage = () => {
  return (
    <Layout>
      <Style />
      <HeaderOne
        topBar
        logo={3}
        headerStyle="header-style-seven"
        autoContainer
        links={false}
      />
      <MobileMenu onePage />
      <BannerOne />
      <AboutSection />
      <ServicesSectionThree className="services-section-three__one-page" />
      <SponsorsSection className="sponsors-section__one-page" />
      <ParallaxSection />
      <ContactSection map form isHomePage={true} />
      <MainFooter normalPadding={false} />
    </Layout>
  );
};

export default HomePage;
