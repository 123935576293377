import React from "react";
import PageBanner from "src/components/BannerSection/PageBanner";
import HeaderOne from "src/components/Header/HeaderOne";
import MobileMenu from "src/components/Header/MobileMenu";
import Layout from "src/components/Layout/Layout";
import MainFooter from "src/components/MainFooter/MainFooter";
import Style from "src/components/Reuseable/Style";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';

const TermsOfServicePage = () => {
    const { t } = useTranslation();
    return (
        <Layout pageTitle={t('pageBanner.termsOfService')}>
            <Style />
            <HeaderOne
                topBar
                logo={3}
                headerStyle="header-style-seven"
                autoContainer
                links={false}
            />
            <MobileMenu />
            <PageBanner title={t('pageBanner.termsOfService')} home={t('pageBanner.home')} />
            <Container className="my-5">
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Card>
                            <Card.Body>
                                <Card.Subtitle className="mb-3">Postanowienia Ogólne</Card.Subtitle>
                                <Card.Text>
                                    Niniejszy regulamin określa zasady korzystania ze strony internetowej WWM Group oraz usług oferowanych za jej pośrednictwem.
                                </Card.Text>

                                <Card.Subtitle className="mb-3">Usługi Świadczone Drogą Elektroniczną</Card.Subtitle>
                                <Card.Text>
                                    <ul>
                                        <li>Formularz Kontaktowy: Umożliwia przesyłanie zapytań do WWM Group.</li>
                                        <li>Rekrutacja: Umożliwia przesyłanie aplikacji na stanowiska pracy.</li>
                                        <li>Newsletter: Zapewnia możliwość subskrypcji informacji o aktualnościach i ofertach.</li>
                                    </ul>
                                </Card.Text>

                                <Card.Subtitle className="mb-3">Warunki Korzystania z Usług</Card.Subtitle>
                                <Card.Text>
                                    <ul>
                                        <li>Użytkownicy zobowiązują się do podawania prawdziwych danych osobowych.</li>
                                        <li>Użytkownicy zobowiązują się nie przesyłać treści o charakterze bezprawnym.</li>
                                    </ul>
                                </Card.Text>

                                <Card.Subtitle className="mb-3">Ochrona Danych Osobowych</Card.Subtitle>
                                <Card.Text>
                                    Dane osobowe użytkowników są przetwarzane zgodnie z Polityką Prywatności.
                                </Card.Text>

                                <Card.Subtitle className="mb-3">Prawa i Obowiązki Użytkownika</Card.Subtitle>
                                <Card.Text>  <ul>
                                    <li>Użytkownicy mają prawo korzystać z usług zgodnie z regulaminem oraz przepisami prawa.</li>
                                    <li>Użytkownicy zobowiązują się do niezakłócania funkcjonowania strony.</li>
                                </ul></Card.Text>

                                <Card.Subtitle className="mb-3">Odpowiedzialność</Card.Subtitle>
                                <Card.Text> <ul>
                                    <li>WWM Group nie ponosi odpowiedzialności za problemy techniczne wynikające z urządzeń lub oprogramowania użytkownika.</li>
                                    <li>WWM Group nie odpowiada za treści przesyłane przez użytkowników, które naruszają prawa innych osób lub przepisy prawa.</li>
                                </ul></Card.Text>

                                <Card.Subtitle className="mb-3">Zmiany Regulaminu</Card.Subtitle>
                                <Card.Text> <ul>
                                    <li>WWM Group zastrzega sobie prawo do wprowadzenia zmian w regulaminie. Informacje o zmianach będą publikowane na stronie.</li>
                                    <li>Zmiany wchodzą w życie z chwilą ich opublikowania.</li>
                                </ul></Card.Text>

                                <Card.Subtitle className="mb-3">Postanowienia Końcowe</Card.Subtitle>
                                <Card.Text> <ul>
                                    <li>Regulamin podlega przepisom prawa polskiego.</li>
                                    <li>Spory wynikające z korzystania ze strony będą rozstrzygane przez sądy powszechne właściwe dla siedziby WWM Group, chyba że przepisy prawa stanowią inaczej.</li>
                                </ul></Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <MainFooter />
        </Layout>
    );
};

export default TermsOfServicePage;
