import { Link } from "react-router-dom";
import React, { useRef, forwardRef, useEffect } from "react";
import TextSplit from "../Reuseable/TextSplit";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const SlideItemOne = forwardRef(({ slide = {}, windowSize }, ref) => {
  const { subtitle, title, discoverMore } = slide;

  const subtitleRef = useRef(null);
  const titleRef = useRef(null);
  const buttonRef = useRef(null);

  const videoSrc = "/videos/14922705-uhd_3840_2160_60fps.mp4";

  useEffect(() => {
    const elements = [titleRef.current, subtitleRef.current, buttonRef.current];

    elements.forEach((el, index) => {
      if (el) {
        el.style.opacity = "0";
        el.style.animation = "fadeIn 1.5s ease-out forwards";
        el.style.animationDelay = `${0.24 + index * 0.4}s`;
      }
    });
  }, [windowSize]);

  return (
    <div ref={ref} style={{ userSelect: "none" }} className="slide-item">
      <div className="image-layer">
        <VideoPlayer src={videoSrc} />
      </div>
      <div className="auto-container">
        <div className="content-box">
          <div className="content">
            <div className="inner text-center" style={{ color: "#ffaa17" }}>
              <h1 ref={titleRef} style={{ color: "#ffaa17" }}>
                <TextSplit text={title} style={{ color: "#ffaa17" }} />
              </h1>
              <div className="sub-title" ref={subtitleRef}>{subtitle}</div>
              <div className="link-box" ref={buttonRef}>
                <Link to="/about">
                  <a className="theme-btn btn-style-one">
                    <i className="btn-curve"></i>
                    <span className="btn-title">{discoverMore}</span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(SlideItemOne);
