import { useTranslation } from 'react-i18next';
import bg from "src/assets/images/main-slider/banner-bg-shape-3.png";

export const BannerOneSection = () => {
  const { t } = useTranslation();
  return {
    bg,
    banners: [
      {
        id: 1,
        bg: "2.jpg",
        subtitle: t('bannerSection.banners.0.subtitle'),
        title: t('bannerSection.banners.0.title'),
        discoverMore: t('bannerSection.banners.0.discoverMore'),
      }
    ]
  };
};
